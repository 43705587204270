import { get, post, postAsForm, put } from "helpers/api_helper.js"


export const userLogin = data => {
  return post("/admin/login", data)
}

export const forgotPassword = data => {
  return post("/admin/passwords/forget", data)
}

export const verifyOtp = data => {
  return post("/admin/passwords/forget/otp-verification", data)
}

export const resetPassword = (data, token) => {
  return post("/admin/passwords/reset", data, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
} 