import React, { useEffect, useState } from "react"
import axios from "axios"
import { Button } from "reactstrap"
// import "./manageUser.css";
import { Link } from "react-router-dom"
import { fireToast } from "common/Toster"
import moment from "moment"
import { saveAs } from "file-saver"
import BASE_URL from "routes/apiRoutes"
import { get } from "helpers/api_helper"

function Withdraw() {
  const [userData, setUserData] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage] = useState(20)
  const [error, setError] = useState()
  const [search, setSearch] = useState("")
  const [pageNo, setPageNo] = useState("")
  const [csv, setcsv] = useState(false)

  useEffect(() => {
    fetchData()
  }, [currentPage, search, csv])

  // const localData = JSON.parse(localStorage.getItem("authUser"))
  // const token = localData?.token

  const fetchData = async () => {
    try {
      const response = await get(`/admin/get_withdraw_history?pageNo=${currentPage}&pageSize=${itemsPerPage}&search=${search}`)
      if (response?.success) {
        setUserData(response?.data)
        setPageNo(response?.meta)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const exportCsv = async () => {
    try {
      const response = await get(`/admin/get_withdraw_history?&exportCsv=true`)
      if (response?.success) {
        // Save the blob as a CSV file
        saveAs(new Blob([response?.data]), "exported_data.csv")
        fireToast("success", "Data downloaded successfully !")
      } else {
        fireToast("error", response.message)
      }
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <div className="Users_list container-fluid mt-5 py-5">
      <div className="row">
        <div className="userinfoSearch d-flex justify-content-between col">
          <h1>Withdraw</h1>
          <input
            type="text"
            value={search}
            className="col-3 ps-3"
            onChange={e => setSearch(e.target.value)}
            placeholder="Search by Wallet Address"
          />
        </div>

        <div className="table_data">
          <table className="table table-bordered table-striped">
            <thead>
              <tr>
                <th>S No.</th>
                <th>User ID</th>
                <th>Amount</th>
                <th>Wallet Address</th>
                <th>Transaction Hash</th>
                <th>Withdrawn Date</th>

              </tr>
            </thead>
            <tbody>
              {userData.map(item => (
                <tr
                  key={item?.id}
                  className={item[`user.is_deleted`] === 1 ? "text-danger" : ""}
                >
                  <td>{item?.id}</td>
                  <td>{item?.user_id}</td>
                  <td>{item?.amount}</td>
                  <td>{item[`user.wallet_address`]}</td>
                  <td>{item?.tx_hash}</td>
                  <td>{moment(item?.created_on).format("MMMM Do YYYY, h:mm:ss a")}</td>
                </tr>
              ))}
            </tbody>
          </table>

        </div>
      </div>
      <div className="text-end me-5">
        <button
          onClick={() => exportCsv()}
          className="btn btn-primary rounded-circle"
        >
          <i className="fas fa-download"></i>
        </button>
      </div>

      <div className="pagination justify-content-center my-4">
        <button
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
          className="btn btn-secondary"
        >
          <i className="fas fa-angle-left"></i>
        </button>
        <h6 className="mx-2 my-auto">{currentPage}</h6>
        <button
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === pageNo?.pages}
          className="btn btn-secondary"
        >
          <i className="fas fa-angle-right"></i>
        </button>
      </div>
    </div>
  )
}

export default Withdraw;