import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button, Form, FormGroup, Label, Input, Container, Row, Col, Card, CardBody } from "reactstrap";
import { fireToast } from "common/Toster";
import moment from "moment";
import BASE_URL from "routes/apiRoutes";
import { get, post } from "helpers/api_helper";

const Wallet = () => {
  const [userData, setUserData] = useState({});
  const [error, setError] = useState(null);
  const [selectedKey, setSelectedKey] = useState("");
  const [value, setValue] = useState("");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await get("/admin/settings");
      if (response.success) {
        setUserData(response?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdateSettings = async () => {
    try {
      const payload = {
        key: selectedKey,
        value: value,
      };

      let response = await post("/admin/settings", payload);
      if (response.success) {
        fireToast("success", "Settings updated successfully!");
        fetchData(); // Refresh data after updating  
      } else {
        fireToast("error", response?.message);
      }
    } catch (error) {
      console.error(error);
      fireToast("error", "Error updating settings");
    }
  };

  return (
    <Container className="mt-5 pt-5">
      <Row>
        <Col md={6}>
          <Card>
            <CardBody>
              <Form>
                <h2 className="mb-4">Update Settings</h2>
                <FormGroup>
                  <Label for="selectKey">Select Key</Label>
                  <Input
                    type="select"
                    name="selectKey"
                    id="selectKey"
                    value={selectedKey}
                    onChange={(e) => setSelectedKey(e.target.value)}
                  >
                    <option value="">Select Key</option>
                    <option value="minimum_staking">Minimum Staking</option>
                    <option value="admin_wallet_address">Admin Wallet Address</option>
                    {/* <option value="total_revenue_amount">Total Revenue Amount</option> */}
                    <option value="maximum_staking">Maximum Staking</option>
                    <option value="daily_reward_percentage">Daily Reward Percentage</option>
                    <option value="withdraw_fee_percentage">Withdraw Fee Percentage</option>
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label for="value">Enter Value</Label>
                  <Input
                    type="text"
                    name="value"
                    id="value"
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                  />
                </FormGroup>
                <div className="d-flex justify-content-center">

                  <Button color="primary" onClick={handleUpdateSettings}>
                    Update Settings
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
        <Col md={6}>
          <Card className="pb-2">
            <CardBody>
              <h2 className="mb-5">Wallet Information</h2>
              {userData && (
                <>
                  <p><strong>Admin Wallet Address:</strong> {userData.admin_wallet_address}</p>
                  {/* <p><strong>Total Revenue Amount:</strong> {userData.total_revenue_amount}</p> */}
                  <p><strong>Minimum Staking: </strong>{userData.minimum_staking}</p>
                  <p><strong>Maximum Staking: </strong>{userData.maximum_staking}</p>
                  <p><strong>Daily Reward Percentage: </strong>{userData.daily_reward_percentage}%</p>
                  <p><strong>Withdraw Fee Percentage:</strong> {userData.withdraw_fee_percentage}%</p>
                  {/* <p>
                    Updated On: {moment(userData.updated_on).format("MMMM Do YYYY, h:mm:ss a")}
                  </p> */}
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Wallet;
