import PropTypes from "prop-types"
import React from "react"
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter, Link } from "react-router-dom"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { userForgetPassword } from "../../store/actions"

// import images
import profile from "../../assets/images/profile-img.png"
import logo from "../../assets/images/logo.svg"
import { forgotPassword } from "Servcies/Userservcies"
import { fireToast } from "common/Toster"

const ForgetPasswordPage = props => {
  //meta title
  document.title = "Forget Password | Defi Club - Admin"

  const dispatch = useDispatch()

  // const validation = useFormik({
  //   // enableReinitialize : use this flag when initial values needs to be changed
  //   enableReinitialize: true,

  //   initialValues: {
  //     email: '',
  //   },
  //   validationSchema: Yup.object({
  //     email: Yup.string().required("Please Enter Your Email"),
  //   }),
  //   onSubmit: async(values) => {
  //     try {
  //       console.log('check')

  //       const forgotPassword = await forgotPassword(values)

  //       console.log('check1')
  //       if (forgotPassword.success === true) {
  //         // changeApiStatus(false, "")
  //         console.log('not-done')
  //         if (forgotPassword?.data?.token) {
  //           fireToast('success', loginResponse.message)
  //           console.log('done')
  //           localStorage.setItem("authUser", JSON.stringify(forgotPassword.data))
  //           props.history.push("/")
  //         }
  //         // setShow(true)
  //       } else {
  //         // showToast(
  //         //   "error",
  //         //   forgotPassword.error === "INVAILD_PASSWORD"
  //         //     ? props.t("Invalid Password")
  //         //     : forgotPassword.error
  //         // )

  //         throw new Error(forgotPassword.error)
  //       }
  //     } catch (err) {
  //       console.log(err)
  //       fireToast('error', err.response.data.error)
  //     }
  //   }
  // });
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Please Enter Your Email"),
    }),
    onSubmit: async (values) => {
      try {
        const forgetPasswordResponse = await forgotPassword({
          email: values.email,
        })
        if (forgetPasswordResponse.success == true) {
          fireToast("success", "Email Send Successfully")
          props.history.push("/otp")
          localStorage.setItem("forgot-email", values.email);
        } else {
          fireToast('error', forgetPasswordResponse.message)
        }
      } catch (err) {
        console.error(err)
        fireToast("error", "Something went wrong !")
      }
    },
  })

  const { forgetError, forgetSuccessMsg } = useSelector(state => ({
    forgetError: state.ForgetPassword.forgetError,
    forgetSuccessMsg: state.ForgetPassword.forgetSuccessMsg,
  }))

  return (
    <React.Fragment>
      <div className="account-pages py-5 d-flex jutify-content-center align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="gradient-bg">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-black">Welcome Back !</h5>
                        <p className="text-black">
                          Sign in to continue to Defi Club.
                        </p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-black">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    {forgetError && forgetError ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {forgetError}
                      </Alert>
                    ) : null}
                    {forgetSuccessMsg ? (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {forgetSuccessMsg}
                      </Alert>
                    ) : null}

                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <Row className="mb-3">
                        <Col className="text-center">
                          <button
                            className="btn custom-btn w-100"
                            type="submit"
                          >
                            Reset
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p className="text-white">
                  Go back to{" "}
                  <Link to="login" className="font-weight-medium text-primary">
                    Login
                  </Link>{" "}
                </p>
                <p className="text-white">
                  © {new Date().getFullYear()} Defi Club. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by BlockTechBrew
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
}

export default withRouter(ForgetPasswordPage)
