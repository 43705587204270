import React, { useState, useEffect } from "react"
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { Container, Button } from "reactstrap"
import axios from "axios"
import { EditorState, convertFromRaw, convertToRaw } from "draft-js"
import "./Cms.css"
import BASE_URL from "routes/apiRoutes"
import { fireToast } from "common/Toster"
import { get, post } from "helpers/api_helper"


const Aboutus = () => {
  const [editorState, setEditorState] = useState(null)

  const onEditorStateChange = editorState => {
    setEditorState(editorState)
  }

  useEffect(() => {
    // Fetch the content from the API when the component mounts
    const fetchContent = async () => {
      try {
        // Make a GET request to fetch the content
        const response = await get("/page/about_us")

        // Extract the content from the API response and update the editor
        if (response?.success && response?.data?.content) {
          setEditorState(
            EditorState.createWithContent(
              convertFromRaw(JSON.parse(response?.data?.content))
            )
          )
        }
      } catch (error) {
        console.error("API Error:", error)
      }
    }
    fetchContent()
  }, [])

  const saveContent = async () => {
    try {
      const content = editorState.getCurrentContent()

      // Make the API call to update the content
      const response = await post("/page",
        {
          content: JSON.stringify(convertToRaw(content)),
          page_type: "about_us",
        })
      if (response.success) {
        fireToast("success", response.message)
      }else{
        fireToast("error", response.message)
      }
    } catch (error) {
      console.error("API Error:", error)
      fireToast("error", "Something went wrong !")
    }
  }

  return (
    <div className="page-content margin-custom custom_wallet">
      <Container fluid>
        <Editor
          editorState={editorState}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          onEditorStateChange={onEditorStateChange}
        />
        <div className="d-flex justify-content-center my-4">
          <Button onClick={saveContent}>Save Content</Button>
        </div>
      </Container>
    </div>
  )
}

export default Aboutus
