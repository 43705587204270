import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { withRouter, Link, useLocation, useHistory } from "react-router-dom";
import { forgotPassword, verifyOtp } from "Servcies/Userservcies";
import { fireToast } from "common/Toster";

import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.svg";

const OtpVerify = (props) => {
  document.title = "Verify OTP | Defi Club - Admin";
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowButton(true);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [setShowButton]);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      email: localStorage.getItem('forgot-email'),
      otp: "",
    },
    validationSchema: Yup.object({
      otp: Yup.string()
        .min(6, "Invalid OTP")
        .max(6, "Invalid OTP")
        .required("This field is required"),
    }),
    onSubmit: async (values) => {
      try {
        const loginResponse = await verifyOtp(values);
        console.log(loginResponse, 'loginResponse')
        if (loginResponse.success == true) {
          fireToast('success', loginResponse.message)
          localStorage.setItem("forgot-token", loginResponse.data.token);
          props.history.push("/reset-password");
        } else {
          fireToast('error', loginResponse.message ?? "Failed to verify OTP");
        }
      } catch (err) {
        console.error(err);
        fireToast('error', "Failed to verify OTP");
      }
    },
  });


  const resendOtp = async () => {
    const email = localStorage.getItem("forgot-email");
    try {
      const loginResponse = await forgotPassword({ email });
      if (loginResponse?.success) {
        fireToast('success', loginResponse.message);
      } else {
        fireToast('error', loginResponse?.message);
      }
    } catch (err) {
      fireToast('error', "Failed to resend OTP");
    }
  }

  return (
    <React.Fragment>
      <div className="account-pages py-5 d-flex jutify-content-center align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="gradient-bg">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-black">Welcome Back!</h5>
                        <p className="text-black">
                          Sign in to continue to Defi Club.
                        </p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-black">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="mb-3">
                        <Label className="form-label">Enter OTP</Label>
                        <Input
                          name="otp"
                          className="form-control"
                          placeholder="Enter OTP"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.otp || ""}
                          invalid={validation.touched.otp && validation.errors.otp}
                        />
                        {validation.touched.otp && validation.errors.otp ? (
                          <FormFeedback type="invalid">{validation.errors.otp}</FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3 text-end">
                        {showButton && (
                          <button
                            className="border-0 bg-transparent text-info"
                            type="button"
                            onClick={resendOtp}
                          >
                            Resend
                          </button>
                        )}
                      </div>
                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-warning"
                          type="submit"
                        >
                          Submit
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p className="text-white">
                  Go back to{" "}
                  <Link to="login" className="font-weight-medium text-primary">
                    Login
                  </Link>
                </p>
                <p className="text-white">
                  © {new Date().getFullYear()} Defi Club. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by BlockTechBrew
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

OtpVerify.propTypes = {
  history: PropTypes.object,
};

export default withRouter(OtpVerify);
