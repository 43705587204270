import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap"
import { Link } from "react-router-dom"

import classNames from "classnames"

//import Charts
import StackedColumnChart from "./StackedColumnChart"

//import action
import { getChartsData as onGetChartsData } from "../../store/actions"

import modalimage1 from "../../assets/images/product/img-7.png"
import modalimage2 from "../../assets/images/product/img-4.png"

// Pages Components
import WelcomeComp from "./WelcomeComp"
import MonthlyEarning from "./MonthlyEarning"
import SocialSource from "./SocialSource"
import ActivityComp from "./ActivityComp"
import TopCities from "./TopCities"
import LatestTranaction from "./LatestTranaction"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

//redux
import { useSelector, useDispatch } from "react-redux"
import axios from "axios"
import DailyStaking from "pages/Staking/DailyStaking"
import BASE_URL from "routes/apiRoutes"
import { get } from "helpers/api_helper"

const Dashboard = props => {
  const [modal, setmodal] = useState(false)
  const [subscribemodal, setSubscribemodal] = useState(false)

  const { chartsData } = useSelector(state => ({
    chartsData: state.Dashboard.chartsData,
  }))

  const [dashboardData, setDashboardData] = useState("")
  useEffect(() => {
    fetchData()
    withdrawdata()
  }, [])

  const fetchData = async () => {
    try {
      const response = await get("/admin/get_dashboard_stats")
      if (response?.success) {
        setDashboardData(response?.data)
      }
    } catch (error) {
      console.error(error)
    }
  }

  // function formatNumberWithMagnitude(number) {
  //   const absNumber = Math.abs(number);
  //   if (absNumber >= 1e12) {
  //     return `${(number / 1e12).toFixed(2)} B`;
  //   } else if (absNumber >= 1e9) {
  //     return `${(number / 1e9).toFixed(2)} M`;
  //   } else if (absNumber >= 1e6) {
  //     return `${(number / 1e6).toFixed(2)} M`;
  //   } else if (absNumber >= 1e3) {
  //     return `${(number / 1e3).toFixed(2)} K`;
  //   } else {
  //     return number.toFixed(2);
  //   }
  // }

  // const users_count = formatNumberWithMagnitude(faqData?.users_count || 0);
  // const formattedAmount = formatNumberWithMagnitude(faqData?.amount_staked || 0);
  // const amount_withdrawn = formatNumberWithMagnitude(faqData?.amount_withdrawn || 0);
  // const total_revenue_amount = formatNumberWithMagnitude(faqData?.total_revenue_amount || 0);
  // const dailyAmountStaked = formatNumberWithMagnitude(faqData?.dailyStats?.dailyAmountStaked?.total || 0);
  // const dailyAmountRewarded = formatNumberWithMagnitude( faqData?.dailyStats?.dailyAmountRewarded?.total || 0);
  // const dailyAmountComissioned = formatNumberWithMagnitude( faqData?.dailyStats?.dailyAmountComissioned?.total || 0);
  // const daliyAmountBurned = formatNumberWithMagnitude(faqData?.dailyStats?.daliyAmountBurned?.total || 0);
  // const dailyAmountWithdrawl = formatNumberWithMagnitude(faqData?.dailyStats?.dailyAmountWithdrawl?.total || 0);
  // const daliyAmountWithdrawlFees = formatNumberWithMagnitude( faqData?.dailyStats?.daliyAmountWithdrawlFees?.total || 0);
  const [withdraw, setWithdraw] = useState({});

  const withdrawdata = async () => {
    try {
      const response = await get(`/admin/settings`)
      if (response?.success) {
        setWithdraw(response?.data)
      }
    } catch (error) {
      console.error(error)
    }
  }


  const reports = [
    { title: "Total User", iconClass: "bx-copy-alt", description: dashboardData?.users_count ?? 0 },
    { title: "Total Stake Amount", iconClass: "bx-archive-in", description: `DCoin ${dashboardData?.amount_staked?.toFixed(2) ?? 0}` },
    { title: "Total Withdraw Amount", iconClass: "bx-purchase-tag-alt", description: `DCoin ${dashboardData?.amount_withdrawn?.toFixed(2) ?? 0}` },
    { title: "Revenue", iconClass: "bx-archive-in", description: `DCoin ${dashboardData?.total_revenue_amount?.toFixed(2) ?? 0}` },
    { title: "Daily Staked Amount", iconClass: "bx-archive-in", description: `DCoin ${dashboardData?.dailyStats?.dailyAmountStaked?.total?.toFixed(2) ?? 0}` },
    { title: "Daily Staking Reward Amount", iconClass: "bx-archive-in", description: `DCoin ${dashboardData?.dailyStats?.dailyAmountRewarded?.total?.toFixed(2) ?? 0}` },
    { title: " Daily Generation Reward Amount", iconClass: "bx-archive-in", description: `DCoin ${dashboardData?.dailyStats?.dailyAmountComissioned?.total?.toFixed(2) ?? 0}` },
    { title: "Daily Withdraw Amount", iconClass: "bx-archive-in", description: `DCoin ${dashboardData?.dailyStats?.dailyAmountWithdrawl?.total?.toFixed(2) ?? 0}` },
    { title: "Daily Burned Amount", iconClass: "bx-archive-in", description: `DCoin ${dashboardData?.dailyStats?.daliyAmountBurned?.total?.toFixed(2) ?? 0}` },
    { title: `Daily Withdraw Charge (${withdraw?.withdraw_fee_percentage}%) Amount`, iconClass: "bx-archive-in", description: `DCoin ${dashboardData?.dailyStats?.daliyAmountWithdrawlFees?.total?.toFixed(2) ?? 0}` },
  ];


  useEffect(() => {
    setTimeout(() => {
      setSubscribemodal(true)
    }, 2000)
  }, [])

  const [periodData, setPeriodData] = useState([])
  const [periodType, setPeriodType] = useState("yearly")

  useEffect(() => {
    setPeriodData(chartsData)
  }, [chartsData])

  const onChangeChartPeriod = pType => {
    setPeriodType(pType)
    dispatch(onGetChartsData(pType))
  }

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(onGetChartsData("yearly"))
  }, [dispatch])

  document.title = "Dashboard | Defi Club - Admin"

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Dashboard")}
          />
          <Row>
            <Col xl="12">
              <Row className="justify-content-center">
                {reports.map((report, key) => (
                  <Col md="3" key={"_col_" + key}>
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              {report.title}
                            </p>
                            <h4 className="mb-0">{report.description}</h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                className={
                                  "bx " + report.iconClass + " font-size-24"
                                }
                              ></i>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              {/* <LatestTranaction /> */}
              {/* <DailyStaking/> */}
            </Col>
          </Row>
        </Container>
      </div>
      <Modal
        isOpen={modal}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          setmodal(!modal)
        }}
      >
        <div>
          <ModalHeader
            toggle={() => {
              setmodal(!modal)
            }}
          >
            Order Details
          </ModalHeader>
          <ModalBody>
            <p className="mb-2">
              Product id: <span className="text-primary">#SK2540</span>
            </p>
            <p className="mb-4">
              Billing Name: <span className="text-primary">Neal Matthews</span>
            </p>

            <div className="table-responsive">
              <Table className="table table-centered table-nowrap">
                <thead>
                  <tr>
                    <th scope="col">Product</th>
                    <th scope="col">Product Name</th>
                    <th scope="col">Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">
                      <div>
                        <img src={modalimage1} alt="" className="avatar-sm" />
                      </div>
                    </th>
                    <td>
                      <div>
                        <h5 className="text-truncate font-size-14">
                          Wireless Headphone (Black)
                        </h5>
                        <p className="text-muted mb-0">$ 225 x 1</p>
                      </div>
                    </td>
                    <td>$ 255</td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <div>
                        <img src={modalimage2} alt="" className="avatar-sm" />
                      </div>
                    </th>
                    <td>
                      <div>
                        <h5 className="text-truncate font-size-14">
                          Hoodie (Blue)
                        </h5>
                        <p className="text-muted mb-0">$ 145 x 1</p>
                      </div>
                    </td>
                    <td>$ 145</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Sub Total:</h6>
                    </td>
                    <td>$ 400</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Shipping:</h6>
                    </td>
                    <td>Free</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Total:</h6>
                    </td>
                    <td>$ 400</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              color="secondary"
              onClick={() => {
                setmodal(!modal)
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Dashboard)
