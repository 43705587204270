import React, { useEffect, useState } from "react"
import axios from "axios"
import { Button } from "reactstrap"
// import "./manageUser.css";
import { Link } from "react-router-dom"
import { fireToast } from "common/Toster"
import moment from "moment"
import { saveAs } from "file-saver"
import BASE_URL from "routes/apiRoutes"

function DailyStaking() {
  const [userData, setUserData] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage] = useState(10)
  const [error, setError] = useState()
  const [search, setSearch] = useState("")
  const [pageNo, setPageNo] = useState("")

  useEffect(() => {
    fetchData()
  }, [currentPage, search ])

  const localData = JSON.parse(localStorage.getItem("authUser"))
  const token = localData?.token

  const fetchData = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const response = await axios.get(
        BASE_URL + `api/v1/admin/daily/staking?pageNo=${currentPage}&pageSize=${itemsPerPage}`,
        config
      )
      setUserData(response?.data?.data)
      setPageNo(response?.data?.meta)
    } catch (error) {
      setError(error)
      console.error(error)
    }
  }

  const exportCsv = async () => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      }
      const response = await axios.get(
        BASE_URL + `api/v1/admin/staking/refferal?&exportCsv=true`,
        config,
        { responseType: "blob" } // Set the responseType to blob
      )
fireToast("success","Data Download InProcess")
      // Save the blob as a CSV file
      saveAs(new Blob([response?.data?.data]), "exported_data.csv")
    } catch (e) {
      setError(e)
      console.error(e)
    }
  }

  

  return (
    <div className="Users_list container-fluid mt-5 py-5">
      <div className="row">
        <div className="userinfoSearch d-flex justify-content-between col">
          <h1>Daily Staking</h1>
          {/* <input
            type="text"
            value={search}
            className="col-3 ps-3"
            onChange={e => setSearch(e.target.value)}
            placeholder="Search by Wallet Address"
          /> */}
        </div>

        <div className="table_data"> 
        <table className="table table-bordered table-striped text-center">
      <thead>
        <tr>
<th>Total </th>
<th>Staked Amount</th>
<th>Staking Reward Amount</th>
<th>Generation Reward Amount</th>
<th>Total Withdraw Amount</th>
<th>Total Burned Amount</th>
<th>Total Withdraw Charge (2%) Amount</th>
        </tr>
      </thead>
      <tbody>
        {userData.map((userinfo) => (
          <tr
            key={userinfo?.id}>
            <td>{userinfo?.amount}</td>
            <td>{userinfo[`user.amount_staked`]}</td>
            <td> - </td>
            <td> - </td>
            <td> - </td>
            <td>{userinfo[`user.amount_burned`]}</td>
            <td> - </td>

          </tr>
        ))}
      </tbody>
    </table>
        </div>
      </div>


      <div className="pagination justify-content-center my-4">
        <button
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
          className="btn btn-secondary"
        >
          <i className="fas fa-angle-left"></i>
        </button>
        <h6 className="mx-2 my-auto">{currentPage}</h6>
        <button
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === pageNo?.pages}
          className="btn btn-secondary"
        >
          <i className="fas fa-angle-right"></i>
        </button>
      </div>
    </div>
  )
}


export default DailyStaking
