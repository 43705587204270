import React, { useEffect, useState } from "react"
import axios from "axios"
import { Button } from "reactstrap"
// import "./manageUser.css";
import { Link } from "react-router-dom"
import { fireToast } from "common/Toster"
import moment from "moment"
import { saveAs } from "file-saver"
import BASE_URL from "routes/apiRoutes"
import { get } from "helpers/api_helper"

function DailyStaking() {
  const [userData, setUserData] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage] = useState(15)
  const [error, setError] = useState()
  const [pageNo, setPageNo] = useState("")

  useEffect(() => {
    fetchData()
  }, [currentPage])

  // const localData = JSON.parse(localStorage.getItem("authUser"))
  // const token = localData?.token

  const fetchData = async () => {
    try {
      // const config = {
      //   headers: {
      //     Authorization: `Bearer ${token}`,
      //     // sortBy,
      //     // exportCsv
      //   },
      // }
      const response = await get(`/admin/contact-us?pageNo=${currentPage}&pageSize=${itemsPerPage}`)
      setUserData(response?.data)
      setPageNo(response?.meta)
    } catch (error) {
      setError(error)
      console.error(error)
    }
  }

  // const exportCsv = async () => {
  //   try {
  //     // const config = {
  //     //   headers: { Authorization: `Bearer ${token}` },
  //     // }
  //     const response = await get(`/admin/contact-us?&exportCsv='true'`,
  //       { responseType: "blob" }
  //     )
  //     fireToast("success", "Data Download InProcess")
  //     // Save the blob as a CSV file
  //     saveAs(new Blob([response?.data]), "exported_data.csv")
  //   } catch (e) {
  //     setError(e)
  //     console.error(e)
  //   }
  // }

  return (
    <div className="Users_list container-fluid mt-5 py-5">
      <div className="row">
        <div className="userinfoSearch d-flex justify-content-between col">
          <h1>Contact Us.</h1>
        </div>
        <div className="table_data">
          <table className="table table-bordered table-striped">
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Adress</th>
                <th>Created On</th>
              </tr>
            </thead>
            <tbody>
              {userData.map(userinfo => (
                <tr key={userinfo?.id} >
                  <td>{userinfo?.id}</td>
                  <td>{userinfo?.name}</td>
                  <td>{userinfo?.email}</td>
                  <td>{userinfo?.phone}</td>
                  <td>{userinfo?.address}</td>
                  <td>
                    {moment(userinfo?.created_on).format(
                      "MMMM Do YYYY, h:mm:ss a"
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {/* <div className="text-end me-5">
        <button
          onClick={() => exportCsv()}
          className="btn btn-primary rounded-circle"
        >
          <i className="fas fa-download"></i>
        </button>
      </div> */}
      <div className="pagination justify-content-center my-4">
        <button
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
          className="btn btn-secondary"
        >
          <i className="fas fa-angle-left"></i>
        </button>
        <h6 className="mx-2 my-auto">{currentPage}</h6>
        <button
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === pageNo?.pages}
          className="btn btn-secondary"
        >
          <i className="fas fa-angle-right"></i>
        </button>
      </div>
    </div>
  )
}

export default DailyStaking
