import axios from "axios";
import React, { useEffect, useState } from "react";
import "./Cms.css"
import {
  Button,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import { Link } from "@material-ui/core";
import { fireToast } from "common/Toster";
import BASE_URL from "routes/apiRoutes";
import { get, del, post } from "helpers/api_helper";

function FAQ() {
  const [faqData, setFaqData] = useState([]);
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [editId, setEditId] = useState("");
  const [Refresh, setRefresh] = useState(false);

  useEffect(() => {
    fetchData();
  }, [Refresh]);

  const fetchData = async () => {
    try {
      // const localData = JSON.parse(localStorage.getItem("authUser"));
      // const token = localData?.token;

      const response = await get("/faqs");
      if (response?.success) {
        setFaqData(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = async (faq_id) => {
    try {
      let response = await del(`/faq/${faq_id}`);
      if (response?.success) {
        setRefresh(prev=>!prev)
        fireToast('success', "Deleted")
      } else {
        fireToast('error', response?.message)
      }
    } catch (error) {
      console.error(error);
      fireToast('error', "Something went wrong !")
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editId) {
        let response = await post(`/faq`, { question, answer, faq_id: `${editId}` });
        if (response.success) {
          fireToast('success', response.message)
          setRefresh(prev=>!prev)
        } else {
          fireToast('error', response?.message)
        }
      } else {
        const response = await post("/faq", { question, answer });
        if (response.success) {
          fireToast('success', response.message)
          setRefresh(prev=>!prev)
          setQuestion("");
          setAnswer("");
          setEditId("");    
        } else {
          fireToast('error', response?.message)
        }
      }
    } catch (error) {
      console.error(error);
      fireToast('success', "Something went wrong !")
    }
  };

  const handleEdit = (faq) => {
    window.scrollTo(0, 0);
    setQuestion(faq.question);
    setAnswer(faq.answer);
    setEditId(faq.faq_id);
  };

  return (
    <React.Fragment>
      <div className="page-content margin-custom custom_faq">
        <Container fluid>
          <h1>FAQ Page</h1>
          <Form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <FormGroup>
                  <Label for="questionInput">Question:</Label>
                  <Input
                    type="text"
                    id="questionInput"
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}
                  />
                </FormGroup>
              </div>
              <div className="col-md-6">
                <FormGroup>
                  <Label for="answerInput">Answer:</Label>
                  <Input
                    type="text"
                    id="answerInput"
                    value={answer}
                    onChange={(e) => setAnswer(e.target.value)}
                  />
                </FormGroup>
              </div>
              <div className="col-md-12 d-flex justify-content-center">
                <Button type="submit">
                  {editId ? "Update" : "Submit"}
                </Button>
              </div>
            </div>
          </Form>

          {faqData.map((faq) => (
            <div key={faq.faq_id} className="d-flex my-3 p-2 faq_data">
              <div className="col-md-11">
                <h3>Question: {faq.question || "N/A"}</h3>
                <p>Answer: {faq.answer || "N/A"}</p>
              </div>
              <div className="col-1 text-center d-flex">
                <Link
                  className="me-2 align-self-center "
                  onClick={() => handleEdit(faq)}
                >
                  <i className="fas fa-edit"></i>
                </Link>
                <Link
                  className="ms-2 align-self-center "
                  onClick={() => handleDelete(faq.faq_id)}
                >
                  <i className="fas fa-trash-alt text-danger"></i>
                </Link>
              </div>
            </div>
          ))}
        </Container>
      </div>
    </React.Fragment>
  );
}

export default FAQ;
