
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { withRouter, Link, useLocation, useHistory } from "react-router-dom";
import { fireToast } from "common/Toster";
import Icon from "react-icons-kit";
import { eyeOff } from 'react-icons-kit/feather/eyeOff';
import { eye } from 'react-icons-kit/feather/eye'
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.svg";
import { resetPassword } from "Servcies/Userservcies"

const OtpVerify = (props) => {
  document.title = "Reset Password | Defi Club - Admin";
  const [type, setType] = useState('password');
  const [typeC, setTypeC] = useState('password');
  const [icon, setIcon] = useState(eyeOff);
  const [iconC, setIconC] = useState(eyeOff);

  const handleToggle = () => {
    if (type === 'password') {
      setIcon(eye);
      setType('text')
    } else {
      setIcon(eyeOff)
      setType('password')
    }
  }
  const handleToggleC = () => {
    if (typeC === 'password') {
      setIconC(eye);
      setTypeC('text')
    } else {
      setIconC(eyeOff)
      setTypeC('password')
    }
  }
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      new_password: "",
      confirm_password: "",
    },
    validationSchema: Yup.object({
      new_password: Yup.string().matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%^&*])[A-Za-z\d@#$!%^&*]{8,}$/, 'Password must have at least 8 characters, atleast one lowercase, one uppercase, one number and one special character')
        .required("Please provide new password"),
      confirm_password: Yup.string()
        .oneOf([Yup.ref("new_password"), null], "Confirm password doesn`t match !")
        .required("Please confirm your new password")
    }),
    onSubmit: async (values) => {
      try {
        const loginResponse = await resetPassword({ newPassword: values.new_password }, localStorage.getItem("forgot-token"));
        console.log(loginResponse, 'loginResponse')
        if (loginResponse.success == true) {
          fireToast('success', loginResponse.message)
          props.history.push("/login");
        } else {
          fireToast('error', loginResponse?.message);
        }
      } catch (err) {
        console.log(err, 'error');
        fireToast('error', 'Something went wrong !');
      }
    },
  });


  return (
    <React.Fragment>
      <div className="account-pages py-5 d-flex jutify-content-center align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="gradient-bg">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-black">Welcome Back!</h5>
                        <p className="text-black">
                          Sign in to continue to Defi Club.
                        </p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-black">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="mb-3">
                        <Label className="form-label">Reset Password</Label>
                        <div className="pass-eye position-relative">
                          <Input
                            name="new_password"
                            className="form-control"
                            placeholder="Enter New Password To Reset"
                            type={type}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.new_password}
                          />
                          <span className="position-absolute" onClick={handleToggle}>
                            <Icon className="mr-10" icon={icon} size={18} />
                          </span>

                        </div>
                        {validation.touched.new_password && validation.errors.new_password ? (
                          <FormFeedback type="invalid">{validation.errors.new_password}</FormFeedback>
                        ) : null}
                      </div>
                      <div classNames="mb-3">
                        <Label className="form-label">Confirm Password</Label>
                        <div className="pass-eye position-relative">

                          <Input
                            name="confirm_password"
                            className="form-control"
                            placeholder="Confirm Password To Reset"
                            type={typeC}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.confirm_password}
                          />
                          <span className="position-absolute" onClick={handleToggleC}>
                            <Icon className="mr-10" icon={iconC} size={18} />
                          </span>

                        </div>
                        {validation.touched.confirm_password && validation.errors.confirm_password ? (
                          <FormFeedback type="invalid">{validation.errors.confirm_password}</FormFeedback>
                        ) : null}
                      </div>
                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-warning"
                          type="submit"
                        >
                          Submit
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p className="text-white">
                  Go back to{" "}
                  <Link to="login" className="font-weight-medium text-primary">
                    Login
                  </Link>
                </p>
                <p className="text-white">
                  © {new Date().getFullYear()} Defi Club. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by BlockTechBrew
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

OtpVerify.propTypes = {
  history: PropTypes.object,
};

export default withRouter(OtpVerify);
