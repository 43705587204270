import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Link, Router, useHistory } from "react-router-dom"
import moment from "moment";  
import {
  Card,
  CardBody,
  Col,
  Row,
} from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "../../assets/scss/style.css"
import "flatpickr/dist/themes/material_blue.css"
import { withTranslation } from "react-i18next"
import Loader from "../../components/Loader"
import axios from "axios"
// import { baseUrl } from "../../config"

const Transaction = props => {
  const history = useHistory();
  const [loader, setLoader] = useState(false)
  let token = JSON.parse(localStorage.getItem("authUser"))

  document.title = "Announcement"
  //delete Job
  const ConfirmDeleteUser = userId => {
  
  }
  const [search, setSearch] = useState("")
  const [status, setStatus] = useState("")
  const [api, setApi] = useState()
  useEffect(() => {
    // console.log(status, "aaaaaaaaaaaaaa")

    // axios
    //   .get(baseUrl + `admin/sub-admins?search=${search}&status=${status}`, {
    //     headers: {
    //       Authorization: `${token.accessToken}`,
    //     },
    //   })
    //   .then(res => {
    //     console.log(res.data.data, "clent resssssssssss")
    //     setApi(res.data.data)
    //   })
    //   .catch(err => {
    //     console.log(err, "errrrrrrrr")
    //   })
  }, [search, status])

  const [active, setActive] = useState('')

  const handleChange = (checked, index,id) => {
    // console.log(checked,'pppppppppppp')
    setActive(checked)
    setLoader(true)
    // axios.post(
    //   baseUrl + "admin/update",
    //   {
    //     isActive: `${checked}`,
    //     id: id,
    //   },
    //   {
    //     headers: {
    //       Authorization: `${token.accessToken}`,
    //     },
    //   }
    // ).then((res)=>{
    //   setLoader(false)
    //   console.log(res,'update')
    // })
    // .catch((err)=>{
    //   setLoader(false)
    //   console.log(err)
    // })
  }
  console.log(active, "activeeeee")
  // console.log(activeId,'active idddd')

  return (
    <React.Fragment>
      {loader ? <Loader /> : ""}
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col lg="12">
              <Card className="border">
                <CardBody className="border-bottom py-2">
                  <div className="d-flex align-items-center">
                    <h4 className="mb-0 card-title flex-grow-1">
                      {" "}
                      Announcement-Management{" "}
                    </h4>

                    {/* <div className="search-box mx-1 my-3 d-inline-block">
                      <div
                        className="position-relative"
                        style={{ marginTop: "10px" }}
                      >
                        <label htmlFor="search-bar-0" className="search-label">
                          <span id="search-bar-0-label" className="sr-only">
                            Search this table
                          </span>
                          <input
                            id="search-bar-0"
                            type="text"
                            className="form-control"
                            placeholder={`Search Name/Email...`}
                            onChange={e => setSearch(e.target.value)}
                          />
                        </label>
                        <i className="bx bx-search-alt search-icon"></i>
                      </div>
                    </div> */}
                    {/* <div className="my-3 mx-1 d-inline-block">
                      <select
                        onChange={e => setStatus(e.target.value)}
                        className="form-control select2 mx-1"
                        style={{ width: "165px" }}
                      >
                        <option value="">Select Status</option>
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                      </select>
                    </div> */}
                    <div className="flex-shrink-0">
                      <Link
                        to="/create-announcement"
                        className="btn btn-primary me-1"
                      >
                        <i className="mdi mdi-plus"></i> Create New Announcement
                      </Link>
                    </div>
                  </div>
                </CardBody>
                <CardBody>
                  <Table
                    id="tech-companies-1"
                    className="table table-striped table-bordered"
                  >
                    <Thead>
                      <Tr>
                        <Th>Sr. No.</Th>
                        <Th>Title</Th>
                        <Th>Description</Th>
                        <Th>Image</Th>
                        <Th>Created At</Th>
                        <Th>Status</Th>
                        <Th>Action</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {/* {api?.items.map((item, i) => {
                        return (
                          <>
                            <tr key={i}>
                              <td>
                                {" "}
                                <span className="co-name"> {i + 1} </span>{" "}
                              </td>
                              <td>
                                <div className="d-flex align-items-center">
                                  <img
                                    src={`https://avatars.dicebear.com/api/bottts/${i}.svg`}
                                    className="avatar-sm me-3 rounded-circle"
                                    alt="img"
                                  />
                                  <div className="">
                                    <h4 className="font-size-14">
                                      {item.userName}{" "}
                                    </h4>
                                  </div>
                                </div>
                              </td>
                              <td>{item.email} </td>
                              <td>
                                <ul className="mb-0">
                                  {item?.permissions.map((item2, id) => {
                                    return <li key={id}> {item2}</li>
                                  })}
                                </ul>
                              </td>
                              <td>{moment(item?.createdAt).format(" DD MMM YYYY")} </td>
                              <td>
                                <div className="square-switch mytoggle">
                                  <input
                                    type="checkbox"
                                    id={`square-switch${i}`}
                                    switch="success"
                                    defaultChecked={item.isActive ? 1 : 0}
                                    // value={1}
                                    onChange={e =>
                                      handleChange(e.target.checked, i,item._id)
                                    }
                                  />
                                  <label
                                    htmlFor={`square-switch${i}`}
                                    data-on-label="Active"
                                    data-off-label="Inactive"
                                  />
                                </div>
                              </td>
                              <td>
                                <ul className="list-unstyled hstack gap-1 mb-0">
                                  <li>
                                    <Link
                                      to={`/admin/sub-admin/update?id=${item._id}`}
                                      className="btn btn-sm btn-soft-primary cstm_btn_color"
                                    >
                                      <i className="mdi mdi-pencil"></i>
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to="#"
                                      className="btn btn-sm btn-soft-danger cstm_btn_color"
                                      onClick={() => {
                                        ConfirmDeleteUser(item._id)
                                      }}
                                    >
                                      <i
                                        className="mdi mdi-delete-outline"
                                        id="deletetooltip"
                                      />
                                    </Link>
                                  </li>
                                </ul>
                              </td>
                            </tr>
                          </>
                        )
                      })} */}


                    </Tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

Transaction.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Transaction)
