 /*eslint-disable */
import PropTypes from "prop-types";
import React, { useState } from "react";

import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label } from "reactstrap";

//redux
import {  useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import Icon from "react-icons-kit";
import {eyeOff} from 'react-icons-kit/feather/eyeOff';
import {eye} from 'react-icons-kit/feather/eye'

// import images
import profile from "assets/images/profile-img.png";
import logo from "assets/images/logo.svg";
import { userLogin } from "Servcies/Userservcies";
import { fireToast } from "common/Toster";
import { apiHeader } from "helpers/api_helper";

const Login = props => {

  const [type, setType] = useState('password');
  const [icon, setIcon] = useState(eyeOff);

  const handleToggle = () => {
    if (type==='password'){
       setIcon(eye);
       setType('text')
    } else {
       setIcon(eyeOff)
       setType('password')
    }
 }

  //meta title
  document.title = "Login | Defi Club - Admin";

  const dispatch = useDispatch();

  const history = useHistory();
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email()
        // .email("Invalid Email Format")
        .required("This Field is Required"),
        password: Yup.string().required("This Field is Required"),
        // password: Yup
        // .string()
        // .min(8, 'Password must be 8 characters long')
        // .matches(/[0-9]/, 'Password requires a number')
        // .matches(/[a-z]/, 'Password requires a lowercase letter')
        // .matches(/[A-Z]/, 'Password requires an uppercase letter')
        // .matches(/[^\w]/, 'Password requires a symbol'),
    }),
    onSubmit: async (values) => {
      try {
        // changeApiStatus(true, "");
        const loginResponse = await userLogin(values);
        if (loginResponse.success === true) {
          fireToast('success', loginResponse.message)
          console.log(loginResponse, "loginResponse");
          // changeApiStatus(false, "");
          localStorage.setItem("authUser", JSON.stringify(loginResponse.result));
          apiHeader()
          history.push("/dashboard")
        } else {
          // changeApiStatus(false, "");
          fireToast('error', loginResponse?.message ?? "Login Error")
        }
      } catch (err) {
        console.log(err);
        fireToast('error', "Login Error")
      }
    },
  });
  
  return (
    <React.Fragment>
      <div className="account-pages py-5 d-flex jutify-content-center align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="gradient-bg">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-black">Welcome Back !</h5>
                        <p className="text-black">Sign in to continue to Defi Club.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-black">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                  <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      {/* {error ? <Alert color="danger">{error}</Alert> : null} */}

                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email ? true : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>                        
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <div className="pass-eye position-relative">
                            <Input
                              name="password"
                              value={validation.values.password || ""}
                              type={type}
                              placeholder="Enter Password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.password && validation.errors.password ? true : false
                              }
                            />
                            <span className="position-absolute" onClick={handleToggle}>
                                <Icon className="mr-10" icon={icon} size={18}/>
                            </span>
                          </div>
                        {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          Remember me
                        </label>
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn custom-btn w-100"
                          type="submit"
                        >
                          Log In
                        </button>
                      </div>


                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-primary">
                          <i className="mdi mdi-lock me-1" />
                          Forgot your password?
                        </Link>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>

              <div className="mt-5 text-center">
               
                <p className="text-white">
                  © {new Date().getFullYear()} Defi Club. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by BlockTechBrew
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};
