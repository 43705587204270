import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Link, Router, useHistory } from "react-router-dom"
import Loader from "../../components/Loader"
import "./custom.css"
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Badge,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
  FormFeedback,
} from "reactstrap"
import Newbreadcrumb from "../../components/Common/Breadcrumb"
import axios from "axios"
import * as Yup from "yup"
import { useFormik } from "formik"
import { BsPencilSquare } from "@react-icons/all-files/bs/BsPencilSquare"
import { MdDeleteForever } from "@react-icons/all-files/md/MdDeleteForever"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import "../../assets/scss/style.css"
import { baseUrl } from "config"
import { FileUploader } from "react-drag-drop-files"

const Dashboard = props => {
  const history = useHistory();
  let auth = JSON.parse(localStorage.getItem("authUser"))
  //meta title
  document.title = "Announcement"

  const fileTypes = [
    "Accepted image formats : JPG",
    "PNG",
    "jpeg",
    " (max. 5MB)",
  ]
  const [image, setImage] = useState()

  const handleimage = file => {
    console.log(file)
    let File = URL.createObjectURL(file)
    setImage(File)
  }

  const editUploadedFile = e => {
    let File = URL.createObjectURL(e.target.files[0])
    setImage(File)
  }

  const [formValues, setFormValues] = useState({
    title: "",
    description: "",
    image: "",
  })
  const [loader,setLoader]=useState(false)
 
  const changeValues = event => {
    // setFormValues({ ...formValues, [event.target.name]: event.target.value })
  }

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: formValues.name,
      description: formValues.email,
      image: formValues.password,
    },
    validationSchema: Yup.object({
      title: Yup.string().required(" Enter Title"),
      description: Yup.string().required(" Enter Description"),
    
    }),
    onSubmit: values => {
      setLoader(true)
    
    //   axios
    //     .post(baseUrl + "admin/sub-admin", {
    //       userName: formValues.name,
    //       firstName:formValues.name,
    //       email: formValues.email,
    //       permissions:prms,
    //       password:formValues.password
    //     }, {
    //       headers: { Authorization: `${auth.accessToken}` },
    //     })
    //     .then(res => {
    //       setLoader(false)
    //       Swal.fire({
    //         position: "center",
    //         icon: "success",
    //         title: "Your Sub-Admin Created Successfully",
    //         showConfirmButton: false,
    //         timer: 2000,
    //       })
    //       setTimeout(()=>{
    //         history.push('/admin/sub-admin')
    //       },2000)
    //     })
    //     .catch(error => {
    //       setLoader(false)
    //       Swal.fire({
    //         position: "center",
    //         icon: "error",
    //         title: error?.response?.data?.error,
    //         showConfirmButton: false,
    //         timer: 2000,
    //       })
    //     })
    },
  })
  const handleChange = (checked, index) => {
    // console.log(checked,'uuuuuuuuuu')
  
  }

  return (
    <React.Fragment>
       {
        loader?
        <Loader/>:
        ""
      }
      <div className="page-content margin-custom mt-2">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Newbreadcrumb items={[{name:'Sub-Admin',link:'/admin/sub-admin'},{name:'Create-Sub-Admin',link:'/admin/create-invoice'}]}/>

          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <Form
                    className="needs-validation create-vendor"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Row>
                      <Col md={12}>
                        <div className="mb-3">
                          <Label> Add Title </Label>
                          <Input
                            type="text"
                            name="title"
                            className="form-control"
                            placeholder="Write here..."
                            // onChange={changeValues}
                          />
                          {validation.touched.title && validation.errors.title ? (
                            <FormFeedback type="invalid">
                              {validation.errors.title}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="mb-3">
                          <Label> Description </Label>
                          <Input
                            type="textarea"
                            name="description"
                            className="form-control"
                            placeholder="Write here..."
                            onChange={changeValues}
                          />
                          {validation.touched.description &&
                          validation.errors.description ? (
                            <FormFeedback type="invalid">
                              {validation.errors.description}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col md="6">
                <Card>
                  <CardBody>
                    <label className="mb-3">Image</label>
                    <br />
                    {image == null || image == "" ? (
                      <FileUploader
                        className="fileuploader form-control"
                        handleChange={handleimage}
                        name="file"
                        types={fileTypes}
                      />
                    ) : (
                      <div className="uploadImageContainer">
                        <img
                          src={image}
                          alt="Avatar"
                          className="image"
                          style={{ height: "100%", maxHeight: "284px" }}
                        />
                        <div className="overlay">
                          <label htmlFor="fileUploader" className="overlayBtn">
                            <BsPencilSquare />
                          </label>
                          <span
                            className="overlayBtn"
                              onClick={()=>setImage("")}
                          >
                            <MdDeleteForever />
                          </span>
                        </div>
                        <input
                          type="file"
                          id="fileUploader"
                          onChange={editUploadedFile}
                          hidden
                        />
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Col>
                      
                      
                     
                    </Row>

                    <div>
                      <Link
                        to="/admin/sub-admin"
                        className="btn btn-secondary w-md mt-0 me-2"
                      >
                        {" "}
                        Cancel{" "}
                      </Link>
                      <button
                        type="submit"
                        className="btn btn-primary w-md mt-0"
                      >
                        Submit
                      </button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Dashboard)
