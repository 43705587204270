import React, { useState, useEffect } from "react";
import PropTypes from "prop-types"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap";
import { fireToast } from "common/Toster";
import Icon from "react-icons-kit";
import { eyeOff } from 'react-icons-kit/feather/eyeOff';
import { eye } from 'react-icons-kit/feather/eye'
import { useFormik } from "formik";
import * as Yup from "yup";
import { put } from "helpers/api_helper";
import { withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

const ChangePasswordForm = (props) => {

  //meta title
  document.title = "Change Password | Defi Club - Admin";
  const [type, setType] = useState('password');
  const [typeN, setTypeN] = useState('password');
  const [typeC, setTypeC] = useState('password');
  const [icon, setIcon] = useState(eyeOff);
  const [iconC, setIconC] = useState(eyeOff);
  const [iconN, setIconN] = useState(eyeOff);

  const handleToggle = () => {
    if (type === 'password') {
      setIcon(eye);
      setType('text')
    } else {
      setIcon(eyeOff)
      setType('password')
    }
  }
  const handleToggleC = () => {
    if (typeC === 'password') {
      setIconC(eye);
      setTypeC('text')
    } else {
      setIconC(eyeOff)
      setTypeC('password')
    }
  }
  const handleToggleN = () => {
    if (typeN === 'password') {
      setIconN(eye);
      setTypeN('text')
    } else {
      setIconN(eyeOff)
      setTypeN('password')
    }
  }

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string().required("Please enter your old password"),
      newPassword: Yup.string().matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%^&*])[A-Za-z\d@#$!%^&*]{8,}$/, 'Password must have at least 8 characters, atleast one lowercase, one uppercase, one number and one special character')
        .required("Please enter a new password"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
        .required("Please confirm your new password"),
    }),
    onSubmit: async (values) => {
      try {
        const response = await put("/admin/passwords",
          {
            oldPassword: values.oldPassword,
            newPassword: values.newPassword,
          }
        );
        if (response.success) {
          fireToast("success", response.message);
          props.history.push('/login')
        } else {
          fireToast("error", response.message);
        }
      } catch (error) {
        console.error("Error:", error);
        fireToast("error", "Failed to change password");
      }
    },
  });



  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Defi Club" breadcrumbItem="Change Password" />
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>

              <Card className="overflow-hidden">
                <CardBody className="pt-0">
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="mb-3">
                        <Label className="form-label">Old Password</Label>
                        <div className="pass-eye position-relative">
                          <Input
                            name="oldPassword"
                            className="form-control"
                            placeholder="Enter Old Password"
                            type={type}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.oldPassword || ""}
                          />
                          <span className="position-absolute" onClick={handleToggle}>
                            <Icon className="mr-10" icon={icon} size={18} />
                          </span>
                        </div>
                        {validation.touched.oldPassword &&
                          validation.errors.oldPassword ? (
                          <FormFeedback type="invalid">
                            {validation.errors.oldPassword}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">New Password</Label>
                        <div className="pass-eye position-relative">
                          <Input
                            name="newPassword"
                            className="form-control my-2"
                            placeholder="Enter New Password"
                            type={typeN}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.newPassword || ""}
                          />
                          <span className="position-absolute" onClick={handleToggleN}>
                            <Icon className="mr-10" icon={iconN} size={18} />
                          </span>
                        </div>
                        {validation.touched.newPassword &&
                          validation.errors.newPassword ? (
                          <FormFeedback type="invalid">
                            {validation.errors.newPassword}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">
                          Confirm New Password
                        </Label>
                        <div className="pass-eye position-relative">

                          <Input
                            name="confirmPassword"
                            className="form-control"
                            placeholder="Confirm New Password"
                            type={typeC}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.confirmPassword || ""}
                          />
                          <span className="position-absolute" onClick={handleToggleC}>
                            <Icon className="mr-10" icon={iconC} size={18} />
                          </span>

                        </div>
                        {validation.touched.confirmPassword &&
                          validation.errors.confirmPassword ? (
                          <FormFeedback type="invalid">
                            {validation.errors.confirmPassword}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <Row className="mb-3">
                        <Col className="text-center">
                          <button
                            className="btn custom-btn w-100"
                            type="submit"
                          >
                            Change Password
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

ChangePasswordForm.propTypes = {
  history: PropTypes.object,
}

export default withRouter(ChangePasswordForm);
