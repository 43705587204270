import axios from "axios";
import accessToken from "./jwt-token-access/accessToken";

//pass new generated access token here
const token = accessToken;

//apply base url for axios
const API_URL = "https://apisdev.deficlub.exchange/api/v1";

export const axiosApi = axios.create({
  baseURL: API_URL,
  // headers: {
  //   "x-key": encriptData(),
  // },
})

axiosApi.interceptors.response.use(
  (response) => {
    return response
  },
  function (error) {
    if (error.response.status === 401) {
      localStorage.clear()
      window.alert('Session Expired !')
      window.location.href = '/login';
      return Promise.reject(error)
    }
    return Promise.reject(error)
  }
)

export const get = async (url, config = {}) => {
  const data = await axiosApi
    .get(url, { ...config })
    .then(response => {
      return response?.data
    })
    .catch(err => {
      return err.response
    })
  return data
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
    .catch(err => {
      return err.response.data
    });
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
    .catch(err => {
      return err.response.data
    });
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
    .catch(err => {
      return err.response.data
    });
}



export const apiHeader = () => {
  const obj = JSON.parse(localStorage.getItem("authUser"))
  if (obj && obj.token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${obj.token}`
  } else {
    return {}
  }
}